import React from 'react';
import {ISimpleLabel, SimpleLabel} from '../simple';
import {TechseeBaseComponent} from '../../../_shared/techsee-base-component';
import {Icon} from '../../../icons/simple-icon/';
import isEmpty from 'lodash/isEmpty';

import './styles.scss';

export interface ILabelWithIcon extends ISimpleLabel {
    icon: string;
    text?: string;
}

export class LabelWithIcon extends TechseeBaseComponent<ILabelWithIcon> {
    renderInternal() {
        this.addCssClass('label-with-icon');
        this.addCssClass(this.props.className);

        const {children, labelFor, text} = this.props;

        return (
            <div className={this.renderCssClasses()}>
                <Icon iconName={this.props.icon} />

                {isEmpty(text) && <SimpleLabel labelFor={labelFor}>{children}</SimpleLabel>}

                {!isEmpty(text) && <span className={'text'}>{text}</span>}
            </div>
        );
    }
}
