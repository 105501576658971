import React from 'react';
import isUndefined from 'lodash/isUndefined';
import Button from 'react-bootstrap/lib/Button';
import {TechseeBaseComponent} from '../../../_shared/techsee-base-component';
import {IClickable, IDesignable, IDisableable, ReactRefCb} from '../../../_shared/reusable-props';

import './styles.scss';

export enum ButtonTypes {
    Primary = 'primary',
    Secondary = 'secondary',
    Closer = 'closer',
    Icon = 'icon',
    Edit = 'edit'
}

export enum ButtonShapes {
    Rectangle = 'rectangle',
    Rounded = 'rounded'
}

export enum ButtonSizes {
    Small = 'small',
    Medium = 'medium',
    Large = 'large'
}

export enum ButtonTypeAttributes {
    Button = 'button',
    Submit = 'submit',
    Reset = 'reset'
}

export type ButtonType =
    | ButtonTypes.Primary
    | ButtonTypes.Secondary
    | ButtonTypes.Closer
    | ButtonTypes.Icon
    | ButtonTypes.Edit;
export type ButtonShape = ButtonShapes.Rectangle | ButtonShapes.Rounded;
export type ButtonSize = ButtonSizes.Small | ButtonSizes.Medium | ButtonSizes.Large;
export type ButtonTypeAttribute =
    | ButtonTypeAttributes.Button
    | ButtonTypeAttributes.Submit
    | ButtonTypeAttributes.Reset;

export interface IDefaultProps {
    buttonType?: ButtonType;
    buttonShape?: ButtonShape;
    buttonSize?: ButtonSize;
    type?: ButtonTypeAttribute;
}

export interface IButton extends IDesignable, IClickable, IDisableable {
    data?: any;
    type?: ButtonTypeAttribute;
    buttonRef?: ReactRefCb;
}

interface IButtonState {
    inClickState: boolean;
}

interface IGenericButton extends IButton, IDefaultProps {}

export class GenericButton extends TechseeBaseComponent<IGenericButton, IButtonState> {
    getInitialState() {
        return {inClickState: false};
    }

    handleClick(e: React.MouseEvent<Button>): void {
        const {onClick, data} = this.props;

        if (!onClick || this.state.inClickState) {
            return;
        }

        const callBackResult = isUndefined(data) ? onClick() : onClick(data);

        if (isUndefined(callBackResult) || !(callBackResult as any).then) {
            return;
        }

        this.setState((state) => ({inClickState: true}));
        (callBackResult as PromiseLike<void>).then(() => {
            this.setState((state) => ({inClickState: false}));
        });
    }

    renderInternal(): JSX.Element {
        this.addCssClass(`btn-${this.props.buttonShape}`);
        this.addCssClass(`btn-${this.props.buttonSize}`);
        this.addCssClass(`btn-${this.props.buttonType}`);
        this.addCssClass(this.props.className);
        this.addCssClassIf('in-click-state', this.state.inClickState);

        return (
            <Button
                className={this.renderCssClasses()}
                onClick={this.handleClick.bind(this)}
                bsStyle={null}
                disabled={this.state.inClickState || this.props.disabled}
                type={this.props.type}
                ref={this.forwardRef(this.props.buttonRef)}>
                {this.props.children}
            </Button>
        );
    }

    static defaultProps: Readonly<IDefaultProps> = {
        buttonType: ButtonTypes.Secondary,
        buttonShape: ButtonShapes.Rectangle,
        buttonSize: ButtonSizes.Medium,
        type: ButtonTypeAttributes.Button
    };
}
