import {ReactText} from 'react';
import {action, computed, observable, toJS} from 'mobx';

export type FieldValidationRule = {
    rule: string;
    message: string;
};

export interface IFieldModel {
    readonly value: ReactText;
    readonly placeholder: string;
    readonly label: string;
    readonly wasChanged: boolean;
    readonly wasBlured: boolean;
    readonly rules: Array<FieldValidationRule>;
    readonly iconName?: string;
    readonly disabled: boolean;
    readonly readOnly: boolean;

    errors: string[];

    setValue(value: ReactText): void;

    snapshotCurrentValue(): void;

    revertValueToLastSnapshot(): void;

    setIconName(iconName: string): void;

    addRule(rule: FieldValidationRule): void;

    setWasChanged(isPristine: boolean): void;

    setWasBlured(wasChanged: boolean): void;

    resetField(): void;

    setFieldError(errors: string | string[]): void;

    setDisabled(disabled: boolean): void;

    setReadonly(readonly: boolean): void;
}

export type SimpleFieldParams = {
    value?: ReactText;
    iconName?: string;
    placeholder?: string;
    label?: string;
};

export class SimpleFieldModel implements IFieldModel {
    @observable private _value: ReactText;
    @observable private _snapshotValue: ReactText;
    @observable private _iconName: string;
    @observable private _initialValue: ReactText;
    @observable private _placeholder = '';
    @observable private _label = '';
    @observable private _wasChanged: boolean;
    @observable private _wasBlured: boolean;
    @observable private _rules: Array<FieldValidationRule>;
    @observable private _disabled = false;
    @observable private _readOnly = false;

    @observable errors: string[];

    constructor(params?: SimpleFieldParams) {
        this._value = (params && params.value) || '';
        this._snapshotValue = '';
        this._iconName = (params && params.iconName) || '';
        this._placeholder = (params && params.placeholder) || '';
        this._label = (params && params.label) || '';

        this._initialValue = this._value;
        this._wasChanged = false;
        this._wasBlured = false;

        this._rules = [];
        this.errors = [];
    }

    @computed get rules(): Array<FieldValidationRule> {
        return toJS(this._rules);
    }

    @computed get value(): ReactText {
        return this._value;
    }

    @computed get iconName(): string {
        return this._iconName;
    }

    @computed get placeholder(): string {
        return this._placeholder;
    }

    @computed get label(): string {
        return this._label;
    }

    @computed get wasChanged(): boolean {
        return this._wasChanged;
    }

    @action setWasChanged(wasChanged: boolean): void {
        this._wasChanged = wasChanged;
    }

    @computed get wasBlured(): boolean {
        return this._wasBlured;
    }

    @computed get disabled(): boolean {
        return this._disabled;
    }

    @computed get readOnly(): boolean {
        return this._readOnly;
    }

    @action setDisabled(disabled: boolean): void {
        this._disabled = disabled;
    }

    @action setReadonly(readOnly: boolean): void {
        this._readOnly = readOnly;
    }

    @action setWasBlured(wasBlured: boolean): boolean {
        this._wasBlured = wasBlured;

        return this._wasBlured;
    }

    @action setValue(value: ReactText): void {
        if (this._value !== value) {
            this._value = value;
            this.setWasChanged(true);
        }
    }

    @action snapshotCurrentValue(): void {
        this._snapshotValue = this._value;
    }

    @action revertValueToLastSnapshot(): void {
        this._value = this._snapshotValue;
    }

    @action setIconName(iconName: string): void {
        this._iconName = iconName;
    }

    @action addRule(rule: FieldValidationRule): void {
        this._rules.push(rule);
    }

    @action setFieldError(errors: string | string[]): void {
        if (typeof errors === 'string') {
            this.errors = [errors];
        } else {
            this.errors = errors;
        }
    }

    @action resetField(): void {
        this._value = this._initialValue;
        this.errors = [];
        this.setWasChanged(false);
        this.setWasBlured(false);
    }
}
