import React from 'react';
import {TechseeBaseComponent} from '../../../_shared/techsee-base-component';
import {ButtonTypes, GenericButton, IButton} from '../generic/index';

export class SecondaryButton extends TechseeBaseComponent<IButton> {
    renderInternal(): JSX.Element {
        return (
            <GenericButton {...this.props} buttonType={ButtonTypes.Secondary}>
                {this.props.children}
            </GenericButton>
        );
    }
}
