"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TechseeBaseControlComponent = void 0;
var techsee_base_component_1 = require("../../_shared/techsee-base-component");
var ValidationRules_1 = require("./ValidationRules");
var TechseeBaseControlComponent = (function (_super) {
    __extends(TechseeBaseControlComponent, _super);
    function TechseeBaseControlComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.handleBlurEvent = _this.handleBlurEvent.bind(_this);
        _this.handleChangeEvent = _this.handleChangeEvent.bind(_this);
        _this.setFieldValue = _this.setFieldValue.bind(_this);
        _this.handleKeyDown = _this.handleKeyDown.bind(_this);
        return _this;
    }
    TechseeBaseControlComponent.prototype.extractValue = function (target) {
        if (typeof target.value !== 'undefined') {
            return target.value;
        }
        return '';
    };
    TechseeBaseControlComponent.prototype.handleBlurEvent = function () {
        this.props.model.setWasBlured(true);
    };
    TechseeBaseControlComponent.prototype.handleChangeEvent = function (event) {
        this.setFieldValue(this.extractValue(event.target));
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };
    TechseeBaseControlComponent.prototype.handleKeyDown = function (event) {
        if (event.key === 'Enter' && this.props.onEnterKey) {
            this.props.onEnterKey(event);
        }
    };
    TechseeBaseControlComponent.prototype.setFieldValue = function (value) {
        var model = this.props.model;
        model.setValue(value);
    };
    TechseeBaseControlComponent.prototype.isRequired = function () {
        var requiredRule = ValidationRules_1.ValidationRules.required();
        return this.props.model.rules.filter(function (rule) { return rule.rule == requiredRule; }).length > 0;
    };
    TechseeBaseControlComponent.prototype.hasErrors = function () {
        var model = this.props.model;
        return model.errors && model.errors.length > 0;
    };
    TechseeBaseControlComponent.prototype.shouldDisplayError = function () {
        var wasTouched = false;
        if (this.props.validationOnChange === true && this.props.model.wasChanged) {
            wasTouched = true;
        }
        if (this.props.validationOnChange !== true && this.props.model.wasBlured) {
            wasTouched = true;
        }
        return this.hasErrors() && wasTouched;
    };
    TechseeBaseControlComponent.prototype.render = function () {
        this.resetCssClasses();
        this.addCssClass(this.props.className);
        this.addCssClassIf('required', this.isRequired());
        return this.renderInternal();
    };
    return TechseeBaseControlComponent;
}(techsee_base_component_1.TechseeBaseComponent));
exports.TechseeBaseControlComponent = TechseeBaseControlComponent;
