import {ReactText} from 'react';
import {computed, observable, reaction} from 'mobx';
import {IReactionDisposer} from 'mobx/lib/core/reaction';
import Validator from 'validatorjs';
import {IFieldModel} from './simple-field-model';

type FormFields = {[fieldName: string]: IFieldModel};
type FormValues = {[fieldName: string]: ReactText};
type FieldRules = {[fieldName: string]: string};
type FieldMessages = {[fieldName: string]: string};
type FieldValidations = {rules: FieldRules; messages: FieldMessages};

export interface IForm {
    fields: Readonly<FormFields>;
    validateOnChange: boolean;
    isValid: boolean;

    addField(name: string, model: IFieldModel): void;

    hasField(name: string): boolean;

    displayFieldsErrors(): void;

    setFieldError(fieldName: string, errors: string | string[], immidiateDisplay: boolean): void;

    resetFormFields(): void;

    removeFields(fieldNames?: string[]): void;
}

export abstract class TechseeFormBase implements IForm {
    @observable private _fields: FormFields;
    @observable private _isSyncRulesValid: boolean;
    @observable validateOnChange: boolean;
    private _modelChangeDisposer?: IReactionDisposer;

    protected constructor() {
        this._fields = {};
        this._isSyncRulesValid = false;
        this.validateOnChange = false;

        this.setupValidationReaction();
    }

    @computed
    get fields(): Readonly<FormFields> {
        return this._fields as Readonly<FormFields>;
    }

    @computed
    get isValid(): boolean {
        return this._isSyncRulesValid;
    }

    addField(name: string, model: IFieldModel): void {
        this._fields[name] = model;
        this.setupValidationReaction();
    }

    removeFields(fieldNames?: string[]): void {
        if (fieldNames) {
            this._fields = Object.keys(this._fields)
                .filter((key) => !fieldNames.includes(key))
                .reduce((obj, key) => ({...obj, [key]: this._fields[key]}), {});
        } else {
            this._fields = {};
        }
    }

    hasField(name: string): boolean {
        // eslint-disable-next-line no-prototype-builtins
        return this._fields.hasOwnProperty(name);
    }

    validateForm(): PromiseLike<boolean> {
        const validationResult = this.createValidator();
        const isValid = validationResult.passes();

        //We use "any" for errors, because of wrong types definition of ValidatorJS
        const errors: any = validationResult.errors.all();

        this.setFormFieldsErrors(errors);
        this._isSyncRulesValid = isValid === true;

        return Promise.resolve(this.isValid);
    }

    displayFieldsErrors(): FormValues {
        const result: FormValues = {};

        Object.keys(this._fields).forEach((key) => {
            this.displayFieldErrors(this._fields[key]);
        });

        return result;
    }

    setFieldError(fieldName: string, errors: string | string[], immediateDisplay = true): void {
        if (!this._fields[fieldName]) {
            throw new Error(`Field ${fieldName} is not exists in form.`);
        }

        this._fields[fieldName].setFieldError(errors);

        if (immediateDisplay) {
            this.displayFieldErrors(this._fields[fieldName]);
        }
    }

    resetFormFields(): void {
        Object.keys(this._fields).forEach((key) => {
            this._fields[key].resetField();
        });
    }

    private setupValidationReaction() {
        this._modelChangeDisposer && this._modelChangeDisposer();
        this._modelChangeDisposer = reaction(this.extractValidationTriggers.bind(this), this.validateForm.bind(this));
    }

    private createValidator(): Validator.Validator<FormValues> {
        const data = this.extractFieldsData();
        const rules = this.extractFieldsRules();

        return new Validator(data, rules.rules, rules.messages);
    }

    private displayFieldErrors(field: IFieldModel) {
        if (this.validateOnChange) {
            field.setWasChanged(true);
        } else {
            field.setWasBlured(true);
        }
    }

    private setFormFieldsErrors(errors: {[fieldName: string]: string[]}) {
        Object.keys(this._fields).forEach((key) => {
            this._fields[key].setFieldError(errors[key] || []);
        });
    }

    private extractFieldsData(): FormValues {
        const result: FormValues = {};

        Object.keys(this.fields).forEach((key) => {
            result[key] = this.fields[key].value;
        });

        return result;
    }

    private extractFieldsRules(): FieldValidations {
        const rules: FieldRules = {};
        const messages: FieldMessages = {};

        Object.keys(this.fields).forEach((key) => {
            const fieldRules = this.fields[key].disabled ? [] : this.fields[key].rules;

            const combinedRules = fieldRules.map((r) => r.rule).join('|');

            if (combinedRules) {
                rules[key] = fieldRules.map((r) => r.rule).join('|');
            }

            fieldRules.forEach((r) => {
                if (r.message) {
                    messages[`${r.rule.split(':')[0]}.${key}`] = r.message;
                }
            });
        });

        return {rules, messages};
    }

    private extractValidationTriggers() {
        const result: any = {};

        Object.keys(this.fields).forEach((key) => {
            result[key + '_blur'] = this.fields[key].wasBlured;
            result[key + '_change'] = this.fields[key].wasChanged;
            result[key] = this.fields[key].value;
        });

        return result;
    }

    snapshotFormValues(): void {
        Object.keys(this.fields).forEach((key) => {
            this.fields[key].snapshotCurrentValue();
        });
    }

    revertToLastSnapshot(): void {
        Object.keys(this.fields).forEach((key) => {
            this.fields[key].revertValueToLastSnapshot();
        });
    }
}
