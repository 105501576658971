"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleFieldModel = void 0;
var mobx_1 = require("mobx");
var SimpleFieldModel = (function () {
    function SimpleFieldModel(params) {
        this._placeholder = '';
        this._label = '';
        this._disabled = false;
        this._readOnly = false;
        this._value = (params && params.value) || '';
        this._snapshotValue = '';
        this._iconName = (params && params.iconName) || '';
        this._placeholder = (params && params.placeholder) || '';
        this._label = (params && params.label) || '';
        this._initialValue = this._value;
        this._wasChanged = false;
        this._wasBlured = false;
        this._rules = [];
        this.errors = [];
    }
    Object.defineProperty(SimpleFieldModel.prototype, "rules", {
        get: function () {
            return (0, mobx_1.toJS)(this._rules);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SimpleFieldModel.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SimpleFieldModel.prototype, "iconName", {
        get: function () {
            return this._iconName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SimpleFieldModel.prototype, "placeholder", {
        get: function () {
            return this._placeholder;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SimpleFieldModel.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SimpleFieldModel.prototype, "wasChanged", {
        get: function () {
            return this._wasChanged;
        },
        enumerable: false,
        configurable: true
    });
    SimpleFieldModel.prototype.setWasChanged = function (wasChanged) {
        this._wasChanged = wasChanged;
    };
    Object.defineProperty(SimpleFieldModel.prototype, "wasBlured", {
        get: function () {
            return this._wasBlured;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SimpleFieldModel.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SimpleFieldModel.prototype, "readOnly", {
        get: function () {
            return this._readOnly;
        },
        enumerable: false,
        configurable: true
    });
    SimpleFieldModel.prototype.setDisabled = function (disabled) {
        this._disabled = disabled;
    };
    SimpleFieldModel.prototype.setReadonly = function (readOnly) {
        this._readOnly = readOnly;
    };
    SimpleFieldModel.prototype.setWasBlured = function (wasBlured) {
        this._wasBlured = wasBlured;
        return this._wasBlured;
    };
    SimpleFieldModel.prototype.setValue = function (value) {
        if (this._value !== value) {
            this._value = value;
            this.setWasChanged(true);
        }
    };
    SimpleFieldModel.prototype.snapshotCurrentValue = function () {
        this._snapshotValue = this._value;
    };
    SimpleFieldModel.prototype.revertValueToLastSnapshot = function () {
        this._value = this._snapshotValue;
    };
    SimpleFieldModel.prototype.setIconName = function (iconName) {
        this._iconName = iconName;
    };
    SimpleFieldModel.prototype.addRule = function (rule) {
        this._rules.push(rule);
    };
    SimpleFieldModel.prototype.setFieldError = function (errors) {
        if (typeof errors === 'string') {
            this.errors = [errors];
        }
        else {
            this.errors = errors;
        }
    };
    SimpleFieldModel.prototype.resetField = function () {
        this._value = this._initialValue;
        this.errors = [];
        this.setWasChanged(false);
        this.setWasBlured(false);
    };
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_value", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_snapshotValue", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_iconName", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_initialValue", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_placeholder", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_label", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_wasChanged", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_wasBlured", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_rules", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_disabled", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "_readOnly", void 0);
    __decorate([
        mobx_1.observable
    ], SimpleFieldModel.prototype, "errors", void 0);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "rules", null);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "value", null);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "iconName", null);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "placeholder", null);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "label", null);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "wasChanged", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "setWasChanged", null);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "wasBlured", null);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "disabled", null);
    __decorate([
        mobx_1.computed
    ], SimpleFieldModel.prototype, "readOnly", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "setDisabled", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "setReadonly", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "setWasBlured", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "setValue", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "snapshotCurrentValue", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "revertValueToLastSnapshot", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "setIconName", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "addRule", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "setFieldError", null);
    __decorate([
        mobx_1.action
    ], SimpleFieldModel.prototype, "resetField", null);
    return SimpleFieldModel;
}());
exports.SimpleFieldModel = SimpleFieldModel;
