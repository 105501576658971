// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PatternsConstants from '@techsee/techsee-common/lib/constants/utils.patterns';

function toValidationJsRegex(regex: RegExp) {
    return `regex:/${regex.source}/`;
}

export const ValidationRules = {
    required: (): string => 'required',
    minLength: (val: number): string => `min:${val}`,
    maxLength: (val: number): string => `max:${val}`,
    phoneNumber: (): string => toValidationJsRegex(PatternsConstants.mobilePhone),
    password: (): string => toValidationJsRegex(PatternsConstants.password),
    regexp: (expression: RegExp): string => toValidationJsRegex(expression),
    email: (): string => 'email',
    numeric: (): string => 'numeric'
};

export default ValidationRules;
