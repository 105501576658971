import React, {ReactType} from 'react';
import {observer} from 'mobx-react';
import {IFormFieldProps, TechseeBaseControlComponent} from '../_shared/techsee-base-control-component';
import FormControl from 'react-bootstrap/lib/FormControl';
import TsFormGroup from '../form-group';
import isUndefined from 'lodash/isUndefined';

import './styles.scss';

interface IFormInputDefault {
    inputType?: string;
    componentClass: ReactType;
    onChange?(e: React.ChangeEvent<FormControl>): void;
    onEnterKey?(e: React.KeyboardEvent<FormControl>): void;
    minLength?: number;
    maxLength?: number;
}

export interface IFormInput extends IFormFieldProps, IFormInputDefault {
    componentClass: ReactType;
    inputRef?: (el: HTMLInputElement) => void;
}

type OptionalProps = {
    minLength?: number;
    maxLength?: number;
};

@observer
// eslint-disable-next-line @typescript-eslint/ban-types
export class FormInput extends TechseeBaseControlComponent<IFormInput, {}> {
    renderInternal() {
        const {uid, className, model, inputRef, componentClass, minLength, maxLength} = this.props;

        this.addCssClass('form-input');
        this.addCssClass(className);

        const restProps: OptionalProps = {};

        if (!isUndefined(minLength)) {
            restProps.minLength = minLength;
        }

        if (!isUndefined(maxLength)) {
            restProps.maxLength = maxLength;
        }

        return (
            <TsFormGroup
                uid={uid}
                className={this.renderCssClasses()}
                isRequired={this.isRequired()}
                showErrors={this.shouldDisplayError()}
                label={model.label}
                labelFor={uid}
                errors={model.errors}
                iconName={model.iconName}>
                <FormControl
                    id={uid}
                    componentClass={componentClass}
                    type={this.props.inputType}
                    placeholder={model.placeholder}
                    value={model.value}
                    readOnly={model.readOnly}
                    disabled={model.disabled}
                    onChange={this.handleChangeEvent}
                    onBlur={this.handleBlurEvent}
                    onKeyDown={this.handleKeyDown}
                    inputRef={inputRef}
                    {...restProps}
                />
            </TsFormGroup>
        );
    }

    static defaultProps: Readonly<IFormInputDefault> = {
        inputType: 'text',
        componentClass: 'input'
    };
}

export default FormInput;
