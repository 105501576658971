import React from 'react';
import {IDesignable} from '../../../_shared/reusable-props';
import {TechseeBaseComponent} from '../../../_shared/techsee-base-component';

import './styles.scss';
import {observer} from 'mobx-react';

export interface ISimpleLabel extends IDesignable {
    labelFor?: string;
    dir?: string;
    onClick?: () => void;
}

@observer
export class SimpleLabel extends TechseeBaseComponent<ISimpleLabel> {
    renderInternal() {
        this.addCssClass('simple-label');
        this.addCssClass(this.props.className);

        const attrs: any = {
            className: this.renderCssClasses()
        };

        if (this.props.labelFor) {
            attrs.htmlFor = this.props.labelFor;
        }

        if (this.props.dir) {
            attrs.dir = this.props.dir;
        }

        return (
            <label onClick={this.props?.onClick} {...attrs}>
                {this.props.children}
            </label>
        );
    }
}
